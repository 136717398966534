import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "title gfont-bold"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_dialog = _resolveComponent("van-dialog");
  const _component_van_toast = _resolveComponent("van-toast");
  return _openBlock(), _createElementBlock(_Fragment, null, [$props.isHtmlDialogShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_dialog, {
    class: "html_dialog",
    show: $data.isShow,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $data.isShow = $event),
    showConfirmButton: false,
    "show-cancel-button": false,
    allowHtml: true,
    onClickOverlay: _cache[1] || (_cache[1] = $event => $setup.showHtmlDialog(false)),
    "z-index": "900",
    "overlay-style": {
      background: 'rgba(0,0,0,0.1)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString($data.titles[$props.htmlDialogType]), 1), _createElementVNode("div", {
      class: "content",
      innerHTML: $data.articleDetail
    }, null, 8, _hoisted_3)]),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true), $props.isLoadingShow ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_van_toast, {
    class: "loading_dialog",
    show: $data.isShow,
    "onUpdate:show": _cache[2] || (_cache[2] = $event => $data.isShow = $event),
    overlay: true,
    "overlay-style": {
      background: 'rgba(0,0,0,0.4)',
      backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)'
    }
  }, {
    message: _withCtx(() => [_createElementVNode("img", {
      width: "100px",
      height: "30px",
      src: this.$store.state.appLogo
    }, null, 8, _hoisted_5), _createElementVNode("span", null, _toDisplayString($props.loadingMessage), 1)]),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true)], 64);
}