export default {
    hi_welcome: '你好! 欢迎',
    product_features: '准确预测购买意向并发送有针对性的 CRM 活动，为您的业务提供服务并以您所需的规模取悦您的客户。',
    login: '登录',
    register: '注册',
    login_now: '立即登录',
    username: '用户名',
    password: '密码',
    forget_password: '忘记密码？',
    register_account: '注册账户？',
    phone_no: '电话号码',
    login_password: '登录密码',
    login_password_confirm: '确认登录密码',
    withdraw_password: '提款密码',
    gender: '性别',
    unknow: '未知',
    female: '女性',
    male: '男性',
    refer_code: '推荐码',
    type_here: '在此处输入',
    i_agree_terms: '我同意条款和条件',
    create_account: '立即创建账户',
    alread_member: '已经是会员？',
    cancel: '取消',
    done: '完成',
    username_rule: '请输入1~30个字符的用户名。',
    password_rule: '请输入密码',
    phoneno_rule: '请输入电话号码',
    password_confirm_rule: '两次输入的密码不同。',
    withdraw_password_rule: '请输入提现密码',
    refre_code_rule: '请输入推荐码',
    i_agree_rule: '请阅读并同意协议。',
    loading: '正在加载中...',
    home: '首页',
    starting: '开始',
    records: '记录',
    profile: '个人资料',
    current: '当前',
    today_profit: '今日利润',
    balance: '余额',
    total_assets: '总资产',
    applications: '应用程序',
    starting_now: '现在开始',
    important_notice: '重要通知',
    all: '全部',
    pending: '待处理',
    completed: '已完成',
    frozen: '冻结',
    total_amout: '总金额',
    profits: '利润',
    start_mission: '开始任务',
    price: '价格',
    commissions: '佣金',
    ration_no: '配给编号',
    app_ratings: '应用评分',
    review_goods_comment: '评论商品评价',
    submit: '提交',
    comment: '评论',
    edit_profile: '编辑个人资料',
    profile_image: '用户头像',
    upload_avatar_hint: '点击头像上传',
    old_password: '旧密码',
    new_password: '新密码',
    new_password_confirm: '确认新密码',
    confirm: '确认',
    forget_password_hint: '*忘记旧密码，请联系客服找回',
    old_password_rule: '请输入旧密码',
    new_password_rule: '请输入新密码',
    kefu_hint: '选择客服联系我们',
    credit_score: '信用评分',
    events: '活动',
    check_in: '签到',
    about_us: '关于我们',
    cert: '证书',
    faq: '常见问题',
    vip: '贵宾',
    terms: '条款',
    transaction: '交易',
    withdrawal: '提款',
    financial_info: '财务信息',
    other: '其他',
    contact_us: '联系我们',
    notifications: '通知',
    logout: '退出',
    certificate: '证书',
    agreement_terms: '协议条款',
    terms_conditions: '条款和条件',
    financial_bind_hint: '尊敬的用户，为了保护您的资金安全，请不要输入您的加密货币钱包密码。我们的工作人员不会要求您输入加密货币钱包 PIN。',
    network: '网络',
    wallet_address: '钱包地址',
    wallet_address_rule: '请输入钱包地址',
    save: '保存',
    finance: '财务',
    apply_now: '立即申请',
    history: '历史记录',
    total_balance: '总余额',
    withdraw_time_hint: '提款处理时间为 15-20 分钟。',
    withdraw_method: '提款方式',
    withdraw_method_hint: '提款将转入加密货币钱包',
    withdraw_amount: '提款金额',
    all: '全部',
    withdraw_password: '提款密码',
    withdraw_amount_rule: '请输入提款金额',
    withdrawal_successful: '提款成功',
    days: '天',
    no_checkin_today: '今天不签到',
    checkin_desc1: '点击日期签到即可领取10金币！每天完成3次任务即可进行工作日签到！ （当天完成任务后可联系客户服务进行兑换）',
    checkin_desc2: '每天完成3次任务，连续4天可获得700美元，连续15天可获得1600美元，连续30天可获得3800美元！',
    sun: '日',
    mon: '一',
    tue: '二',
    wed: '三',
    thur: '四',
    fri: '五',
    sat: '六',
    token_expire: '令牌已过期，请重新登录。',
    lang_set: '语言设置',
    exchange: '兑换',
    exchange_history: '兑换记录',
    total_coin: '总的金币',
    today_get_coin: '今日已获得{todayCoin}金币',
    coin_hint: '金币可兑换钱，存入你的余额中',
    exchange_scale_title: '对应比例',
    exchange_scale_value: '{exchangeRate}金币兑换{exchangeFor}美金',
    exchange_amount: '兑换金额',
    exchange_amount_rule: '请输入兑换金额',
    exchange_successful: '兑换成功',
    exchange_record: '恭喜你，{coin}金币成功兑换{amount}美金，已存入你的余额',
    recharge: '充值',
    reject: '拒绝',
}