import axios from "axios";
import { showDialog } from "vant";
import i18n from '../lang/i18n';


function getBaseUrl() {
    // return "https://admin.newoxatis.shop";
    // return "https://shop.bela.ltd";
    // return "https://new-oxatis.com";
    return process.env.VUE_APP_API_URL;
}

const http = axios.create({
    baseURL: getBaseUrl() + "/api/",
    timeout: 10000,
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    const token = localStorage.getItem("token");
    if(token != null){
        config.headers["Authorization"] = "Bearer "+token
    }
    var lang = localStorage.getItem('language') || 'en';
    config.headers["Lang"] = lang;
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data;
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        // 如果是 401 错误，并且请求没有被重试过
        originalRequest._retry = true;

        // 清除 token 并重定向到登录页面
        localStorage.removeItem('token');

        // 显示提示弹窗
        // alert('The token has expired, please log in again.');
        showDialog({
          message: i18n.global.t('token_expire'),
          confirmButtonText: i18n.global.t('confirm'),
        }).then(() => {
          // on close
          window.location.href = '/'; // 重定向到登录页面
        });

        return Promise.reject(error);
    }
    return Promise.reject(error);
  });

  export default http