export default {
    hi_welcome: 'Hi! Welcome',
    product_features: 'Accurately predict buying intent and send targeted CRM campaigns that deliver for your business and delight your customers with the scale you need..',
    login: 'Login',
    register: 'Register',
    login_now: 'Login Now',
    username: 'Username',
    password: 'Password',
    forget_password: 'Forgot Password?',
    register_account: 'Register Account?',
    phone_no: 'Phone NO',
    login_password: 'Login Password',
    login_password_confirm: 'Confirm Login Password',
    withdraw_password: 'Withdrawal Password',
    gender: 'Gender',
    unknow: 'Unknown',
    female: 'Female',
    male: 'Male',
    refer_code: 'Referral Code',
    type_here: 'Type Here',
    i_agree_terms: 'I agree to the terms & conditions',
    create_account: 'Create Account Now',
    alread_member: 'Already a member?',
    cancel: 'Cancel',
    done: 'Done',
    username_rule: 'Please enter 1~30 characters for username.',
    password_rule: 'Please enter password',
    phoneno_rule: 'Please enter Phone Number',
    password_confirm_rule: 'Two passwords entered are different.',
    withdraw_password_rule: 'Please enter withdrawal password',
    refre_code_rule: 'Please enter referral code',
    i_agree_rule: 'Please read and agree to the agreement.',
    loading: 'Loading...',
    home: 'Home',
    starting: 'Starting',
    records: 'Records',
    profile: 'Profile',
    current: 'Current',
    today_profit: 'Today Profit',
    balance: 'Balance',
    total_assets: 'Total Assets',
    applications: 'Applications',
    starting_now: 'Starting Now',
    important_notice: 'Important Notice',
    all: 'All',
    pending: 'Pending',
    completed: 'Completed',
    frozen: 'Frozen',
    total_amout: 'Total amout',
    profit: 'Profit',
    start_mission: 'Start Mission',
    price: 'Price',
    commissions: 'Commissions',
    ration_no: 'Ration No.',
    app_ratings: 'App Ratings',
    review_goods_comment: 'Comment Goods Review',
    submit: 'Submit',
    comment: 'Comment',
    edit_profile: 'Edit Profile',
    profile_image: 'Profile Image',
    upload_avatar_hint: 'Click on the avatar to upload',
    old_password: 'Old Password',
    new_password: 'New Password',
    new_password_confirm: 'Confirm New Password',
    confirm: 'Confirm',
    forget_password_hint: '*Forgot the old password, please contact customer service to retrieve it',
    old_password_rule: 'Please enter old password',
    new_password_rule: 'Please enter new password',
    kefu_hint: 'Choose customer service to contact us',
    credit_score: 'Credit Score',
    events: 'Events',
    check_in: 'Check-In',
    about_us: 'About US',
    cert: 'Cert',
    faq: 'FAQ',
    vip: 'VIP',
    terms: 'Terms',
    transaction: 'Transaction',
    withdrawal: 'Withdrawal',
    financial_info: 'Financial Information',
    other: 'Other',
    contact_us: 'Contact Us',
    notifications: 'Notifications',
    logout: 'Logout',
    certificate: 'Certificate',
    agreement_terms: 'Agreement Terms',
    terms_conditions: 'Terms & Conditions',
    financial_bind_hint: 'Dear users, in order to protect the security of your funds, please do not enter your Cryptocurrency Wallet password. Our staff will not ask you to enter your Cryptocurrency Wallet PIN.',
    network: 'Network',
    wallet_address: 'Wallet Address',
    wallet_address_rule: 'Please input wallet address',
    save: 'Save',
    finance: 'Finance',
    apply_now: 'Apply Now',
    history: 'History',
    total_balance: 'Total Balance',
    withdraw_time_hint: 'Withdrawal processing time is 15-20 minutes.',
    withdraw_method: 'Withdraw Method',
    withdraw_method_hint: 'Withdrawal will be transferred to Cryptocurrency Wallet',
    withdraw_amount: 'Withdraw Amount',
    all: 'All',
    withdraw_password: 'Withdraw Password',
    withdraw_amount_rule: 'Please input withdraw amount',
    withdrawal_successful: 'Withdrawal successful',
    days: 'Days',
    no_checkin_today: 'Non Check-In Today',
    checkin_desc1: '-Click the date to sign in and receive 10 gold coins! Complete 3set to check in for a weekday! (You can contact customer service for redemption after completing the tasks on the same day) ',
    checkin_desc2: '-Complete 3set per day, 4 days 700USDT, 15 days 1600USDT, 30 days 3800USDT!',
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thur: 'Thur',
    fri: 'Fri',
    sat: 'Sat',
    token_expire: 'The token has expired, please log in again.',
    lang_set: 'Language settings',
    exchange: 'Exchange',
    exchange_history: 'Exchange record',
    total_coin: 'Total gold coins',
    today_get_coin: 'Today you have obtained {todayCoin} gold coins',
    coin_hint: 'Gold coins can be exchanged for money and deposited into your balance',
    exchange_scale_title: 'Corresponding ratio',
    exchange_scale_value: '{exchangeRate} gold coins exchanged for {exchangeFor} US dollars',
    exchange_amount: 'Exchange amount',
    exchange_amount_rule: 'Please enter the exchange amount',
    exchange_successful: 'Exchange successful',
    exchange_record: 'Congratulations, {coin} gold coins have been successfully exchanged for {amount} US dollars and have been deposited into your balance',
    recharge: 'Recharge',
    reject: 'Reject',
}