import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";

export default createStore({
    plugins: [createPersistedState()],
    state(){
        return{
            // baseUrl: "https://admin.newoxatis.shop",
            // baseUrl: "https://shop.bela.ltd",
            // baseUrl: "https://new-oxatis.com",
            baseUrl: process.env.VUE_APP_API_URL,
            moneySymbol: '$',
            isShowUserCenterPopup: true,
            appLogo: "",
            copyRight: "",
            referralCodeRule: 0,
            customerServiceHour: "",
            userDetail: {
                memberAccount: null,
                memberProfile: null,
                memberLevelIcon: null,
                referralCode: null,
                creditScore: null,
                todayProfit: null,
                balance: null,
                totalAsset: null,
                todayOrder: 0,
                maximunOrder: 0,
            },
        } 
    },

    mutations: {
        updateAppLogo(state, appLogo){
            state.appLogo = appLogo
        },
        updateCopyRight(state, copyRight){
            state.copyRight = copyRight
        },
        updateReferralCodeRule(state, rule){
            state.referralCodeRule = rule
        },
        updateCustomServiceHour(state, time){
            state.customerServiceHour = time
        },
        updateUserDetail(state, userDetail){
            state.userDetail = userDetail
        },
        showUserCenterPopup(state, isShow){
            state.isShowUserCenterPopup = isShow
        },
    },

    //为异步操作准备的
    actions: {
        updateAppLogo({ commit }, appLogo) {
            commit('updateAppLogo', appLogo);
        },
        updateCopyRight({ commit }, copyRight) {
            commit('updateCopyRight', copyRight);
        },
        updateReferralCodeRule({ commit }, rule) {
            commit('updateReferralCodeRule', rule);
        },
        updateCustomServiceHour({ commit }, time) {
            commit('updateCustomServiceHour', time);
        },
        updateUserDetail({ commit }, userDetail) {
            commit('updateUserDetail', userDetail);
        },
        showUserCenterPopup({ commit }, isShow){
            commit('showUserCenterPopup', isShow);
        },
    }

    
})