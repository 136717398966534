import { createI18n } from "vue-i18n";
import en from "./en";
import zh from "./zh";
import ja from "./ja";
import ma from "./ma";
import th from "./th";

const i18n = createI18n({
    locale:localStorage.getItem('language') || 'en', // 默认是中文
    globalInjection:true,//全局配置$t
    legacy:false,
    messages:{en,zh,ja,ma,th}// 需要做国际化的语种
})

export default i18n