export default {
    hi_welcome: 'こんにちは!ようこそ',
    product_features: '購入意欲を正確に予測し、ビジネスに貢献し、必要な規模で顧客を満足させるターゲット CRM キャンペーンを送信します。',
    login: 'ログイン',
    register: '登録',
    login_now: '今すぐログイン',
    username: 'ユーザー名',
    password: 'パスワード',
    remember_password: 'パスワードを忘れましたか?',
    register_account: 'アカウントを登録しますか?',
    phone_no: '電話番号',
    login_password: 'ログイン パスワード',
    login_password_confirm: 'ログイン パスワードの確認',
    withdraw_password: '出金パスワード',
    gender: '性別',
    unknow: '不明',
    female: '女性',
    male: '男性',
    refer_code: '紹介コード',
    type_here: 'ここに入力',
    i_agree_terms: '利用規約に同意します',
    create_account: '今すぐアカウントを作成',
    alread_member: 'すでにメンバーですか?',
    cancel: 'キャンセル',
    done: '完了',
    username_rule: 'ユーザー名は1～30文字で入力してください。',
    password_rule: 'パスワードを入力してください',
    phoneno_rule: '電話番号を入力してください',
    password_confirm_rule: '入力した2つのパスワードが異なります。',
    withdraw_password_rule: '出金パスワードを入力してください',
    refre_code_rule: '紹介コードを入力してください',
    i_agree_rule: '利用規約を読んで同意してください。',
    loading: '読み込み中...',
    home: 'ホーム',
    starting: '開始中',
    records: '記録',
    profile: 'プロフィール',
    current: '現在',
    today_profit: '本日の利益',
    balance: '残高',
    total_assets: '総資産',
    applications: 'アプリケーション',
    starting_now: '現在開始',
    important_notice: '重要なお知らせ',
    all: 'すべて',
    pending: '保留中',
    complete: '完了',
    freeze: '凍結',
    total_amout: '合計金額',
    profit: '利益',
    start_mission: 'ミッション開始',
    price: '価格',
    Commissions: '手数料',
    ration_no: '配給番号',
    app_ratings: 'アプリ評価',
    review_goods_comment: '商品レビューのコメント',
    submit: '送信',
    comment: 'コメント',
    edit_profile: 'プロフィール編集',
    profile_image: 'プロフィール画像',
    upload_avatar_hint: 'アップロードするアバターをクリックしてください',
    old_password: '古いパスワード',
    new_password: '新しいパスワード',
    new_password_confirm: '新しいパスワードを確認',
    confirm: '確認',
    forget_password_hint: '*古いパスワードを忘れた場合は、カスタマー サービスに連絡してパスワードを取得してください',
    old_password_rule: '古いパスワードを入力してください',
    new_password_rule: '新しいパスワードを入力してください',
    kefu_hint: 'お問い合わせにはカスタマー サービスを選択してください',
    credit_score: 'クレジットスコア',
    events: 'イベント',
    check_in: 'チェックイン',
    about_us: '会社概要',
    cert: '証明書',
    faq: 'よくある質問',
    vip: 'VIP',
    terms: '利用規約',
    transaction: '取引',
    withdrawal: '出金',
    financial_info: '財務情報',
    other: 'その他',
    contact_us: 'お問い合わせ',
    notifications: '通知',
    logout: 'ログアウト',
    certificate: '証明書',
    agreement_terms: '契約条件',
    term_conditions: '利用規約',
    financial_bind_hint: 'ユーザーの皆様、資金のセキュリティを保護するため、暗号通貨ウォレットのパスワードを入力しないでください。弊社のスタッフは、お客様の暗号通貨ウォレットのPINの入力をお願いすることはありません。',
    network: 'ネットワーク',
    wallet_address: 'ウォレットアドレス',
    wallet_address_rule: 'ウォレットアドレスを入力してください',
    save: '保存',
    finance: 'ファイナンス',
    apply_now: '今すぐ申し込む',
    history: '履歴',
    total_balance: '合計残高',
    withdraw_time_hint: '出金処理時間は15〜20分です。',
    withdraw_method: '出金方法',
    withdraw_method_hint: '出金は暗号通貨ウォレットに転送されます',
    withdraw_amount: '出金金額',
    all: 'すべて',
    withdraw_password: '出金パスワード',
    withdraw_amount_rule: '出金金額を入力してください',
    withdrawal_successful: '出金成功しました',
    days: '日',
    no_checkin_today: '本日チェックインなし',
    checkin_desc1: '日付をクリックしてサインインすると、10ゴールドコインがもらえます！平日にチェックインするために、毎日3セットを完了してください！ （当日にタスクを完了したら、カスタマーサービスに連絡して交換できます）',
    checkin_desc2: '毎日3セットを完了し、4日間で700USDT、15日間で1600USDT、30日間で3800USDTを獲得！',
    sun: '日',
    mon: '月',
    tue: '火',
    wed: '水',
    thur: '木',
    fri: '金',
    sat: '土',
    token_expire: 'トークンの有効期限が切れています。再度ログインしてください。',
    lang_set: '言語設定',
    exchange: '交換',
    exchange_history: '交換記録',
    total_coin: '金貨の合計',
    today_get_coin: '今日、{todayCoin} 個の金貨を入手しました',
    coin_hint: 'ゴールド コインはお金に交換でき、残高に入金できます',
    exchange_scale_title: '対応する割合',
    exchange_scale_value: '{exchangeRate} の金貨を {exchangeFor} 米ドルに交換します',
    exchange_amount: '交換金額',
    exchange_amount_rule: '両替金額を入力してください',
    exchange_successful: '交換が成功しました',
    exchange_record: 'おめでとうございます。{coin} 個の金貨が {amount} 米ドルと正常に交換され、残高に入金されました',
    recharge: '充電する',
    reject: '拒否する',
}