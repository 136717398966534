import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import GlobalDialog from './views/public/GlobalDialog.vue';
import useLoading from './composables/useLoading';
import useHtmlDialog from './composables/useHtmlDialog';
import { onBeforeUnmount, onMounted, ref } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const {
      isLoadingShow,
      loadingMessage
    } = useLoading();
    const {
      isHtmlDialogShow,
      htmlDialogType
    } = useHtmlDialog();
    const beforeUnloadTime = ref(0);
    onMounted(() => {
      // 添加 beforeunload 事件监听
      window.addEventListener('beforeunload', beforeunloadHandler);
      window.addEventListener('unload', unloadHandler);
    });
    onBeforeUnmount(() => {
      // 移除事件监听
      window.removeEventListener('beforeunload', beforeunloadHandler);
      window.removeEventListener('unload', unloadHandler);
    });
    const beforeunloadHandler = () => {
      beforeUnloadTime.value = new Date().getTime();
    };
    const unloadHandler = () => {
      let time = new Date().getTime() - beforeUnloadTime.value;
      if (time <= 5) {
        //关闭
        clearToken();
      } else {
        // 刷新
      }
    };
    const clearToken = () => {
      localStorage.removeItem('token');
    };
    return (_ctx, _cache) => {
      const _component_RouterView = _resolveComponent("RouterView");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_RouterView), _createVNode(GlobalDialog, {
        isLoadingShow: _unref(isLoadingShow),
        loadingMessage: _unref(loadingMessage),
        isHtmlDialogShow: _unref(isHtmlDialogShow),
        htmlDialogType: _unref(htmlDialogType)
      }, null, 8, ["isLoadingShow", "loadingMessage", "isHtmlDialogShow", "htmlDialogType"])]);
    };
  }
};