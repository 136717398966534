import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/main.css'
// import 'amfe-flexible'
import router from './router'
import store from './store'
import i18n from './lang/i18n'

const app = createApp(App)
app.use(router).use(store).use(i18n).mount('#app')
